/* General styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
  }
  
  .main {
    padding: 2rem;
    text-align: center;
  }
  
  .main_heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  /* Cards container */
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Individual card styling */
  .cards_item {
    margin: 1rem;
    width: 300px;
  }
  
  .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .card_image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card_content {
    padding: 1rem;
    text-align: left;
  }
  
  .card_title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .card_description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .upcharge {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
  }
  
  .upcharge:hover {
    background-color: #0056b3;
  }
  