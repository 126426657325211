@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html{
      font-family: 'Raleway', sans-serif;

  }
}
#header{
  position: fixed;
}

.fixed-header{
  padding-top: 15px;
}
.product-img img{
  height: 250px !important;
}
.rounded-projects-img{
  border-radius: 100%;
  width: 50px !important;
  height: 50px !important;
}
p,h1,h2,h3,h4,h5{
  color: #EAF9E7;
}

body {
  padding: 0;
  margin: 0 0 200px 0;
}
.hero {
  display: inline-block;
  width: 100%;
  height: 50vh;
  position: relative;

}
.diagonal-hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  background: #1d6656;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1d916a, #2b5876);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #167958, #2b5876); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
}

/* // creates randomized star sizes */
@function stars($n) {
  $value: '#{random(2000)}px #{random(2000)}px #767676';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #767676';
  }
  @return unquote($value);
}

/* // store random stars */
$stars-small: stars(700);
$stars-medium: stars(200);
$stars-big: stars(100);


.stars {
  z-index: -1;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 1s ease-in-out;
}

.stars > .small {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $stars-small;
  animation: starsAnimation 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $stars-small;
  }
}

.stars > .medium {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $stars-medium;
  animation: starsAnimation 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $stars-medium;
  }
}

.stars > .big {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $stars-big;
  border-radius: 100%;
  animation: starsAnimation 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $stars-big;
    border-radius: 100%;
  }
}

// swap from/to values to reverse animation
@keyframes starsAnimation {
  from {
    transform: translateY(-2000px);
  }
  to {
    transform: translateY(0px);
  }
}


@keyframes fly-in {
  0% {
    top: 0;
    right: 0;
    font-size: 1.325rem;
  }

  25% {
    top: 0;
    right: -200%;
    font-size: 1.325rem;
  }

  26% {
    font-size: 2rem;
  }

  100% {
    top: 2rem;
    right: 0;
    font-size: 2rem;
  }
}

@keyframes fly-out {
  0% {
    top: 2rem;
    right: 0;
    font-size: 2rem;
  }

  50% {
    top: 0;
    right: -200%;
    font-size: 1.325rem;
  }

  100% {
    top: 0;
    right: 0;
    font-size: 1.325rem;
  }
}

// Solutions
.solutions-title{
  color: #1d916a;
  
}

.card-title{
  font-size: larger;
  color: #333635;
}
.card-text{
  color: #515856;
}
